import React from 'react';
import { EffectFade, Navigation } from 'swiper';
import { Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import img from '../../assets/images/eternia/Home/contacto.png';
import { Link } from 'react-router-dom';

const  InteresadoNichos = () => {


    return (
        <div className="theme_slider bg-black">
            <div className="container">
                <div className="swiper swiper_theme_slider" data-swiper-autoplay="2000">
                    <Swiper
                        modules={[Pagination, EffectFade, Navigation]}
                        effect="slide"
                        slidesPerView={1}
                        loop={true}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        navigation={{
                            prevEl: '.swiper-button-prev',
                            nextEl: '.swiper-button-next',
                        }}
                    >
                        {
                            <SwiperSlide>
                                <div className="slider" style={{ backgroundImage: 'url(images/eternia/Home/img-contacto-eternia.png)' }}>
                                    <div className="slide_content">
                                        <div className="slide_content_wrapper mb-0 h-auto bg-dark-100">
                                            <div className="slide_content_inner">
                                                <h4 className='mBold fz-36 text-white'>¿Estás interesado en adquirir un nicho?</h4>
                                                <div className="row mb-4">
                                                    <div className="col-lg-6 col-sm-6 col-md-6">
                                                        <h5 className='mExtraLight fz-18 txt-oro mb-2'>TELÉFONO</h5>
                                                        <h6 className='mRegular fz-18'>
                                                            <Link to="tel:8004722767" target="_blank" rel="noopener noreferrer" className='text-white'>800 472 2767</Link>
                                                        </h6>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6 col-md-6">
                                                        <div >
                                                            <h5 className='mExtraLight fz-18 txt-oro mb-2'>WHATSAPP</h5>
                                                            <h6 className='mRegular fz-18'>
                                                                <Link to="https://api.whatsapp.com/send?phone=5659272902" target="_blank" rel="noopener noreferrer" className='text-white'> 56 5927 2902</Link>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="details_link">
                                                    <Link to="/Contacto">
                                                        <span className="link_text">CONTÁCTANOS</span>
                                                        <span className="link_icon">
                                                            <span className="line"></span>
                                                            <span className="circle"></span>
                                                            <span className="dot"></span>
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        }

                    </Swiper>

                    
                </div>

            </div>
        </div>
    );
};

export default InteresadoNichos;